import React from 'react';
import '../App.css';
import Button from '@mui/material/Button';
import headerImg1 from "../img/IMG_2817.jpg"
import headerImg2 from "../img/IMG_2818.jpg"
import headerImg3 from "../img/IMG_2819.jpg"
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

function NolHeader() {
  return (
    <header className="App-header">
        <div className='nolHeader'>
            {/* <motion.div>
                <Button variant="contained" color="primary" href='https://www.instagram.com/nol_hair_create/'>Instagram</Button>
            </motion.div> */}
            {/* <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.4, delay: 0.3 }}> */}
                {/* <div>
                    <img className='topImage' src={headerImg1} alt='nol-image' />
                </div>
            </motion.div>
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.4, delay: 0.7 }}>
                <div>
                    <img className='topImage' src={headerImg2} alt='nol-image' />
                </div>
            </motion.div> */}
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 2, delay: 1 }}>
                <div style={{width:'100%'}}>
                    <img className='topImage' src={headerImg3} alt='nol-image' />
                </div>
            </motion.div>
            {/* <div className='headerTitle'>nol</div>
            <div className='nolKana'>-ノル-</div>
            <div>HAIR CREATE<br />2024.12.11 OPEN</div> */}
        </div>
    </header>
  );
}

export default NolHeader;
