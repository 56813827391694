import React from 'react';
import '../App.css';
import { motion } from 'framer-motion';
import mainImg1 from "../img/IMG_2820.jpg"
import { useInView } from 'react-intersection-observer';
import { ReactNode } from 'react';
import aboutImg from "../img/IMG_2826.jpg";
import Button from '@mui/material/Button';

function createData(menuName: string, value: string) {
    return { menuName, value };
  }

const TABLE_OBJECT = [
    createData('店名', 'nol　-ノル-'),
    createData('住所', '愛知県瀬戸市赤重町126-2'),
    createData('電話番号', '070-9057-5201'),
    createData('営業時間', '9:30～19:00　最終受付:18:45'),
    createData('定休日', '月曜日'),
    createData('料金', '￥1,500円')
  ];

function NolMain() {
    type FadeAnimationProps = {
        children: ReactNode;
    };
      
    const FadeAnimation = (props: FadeAnimationProps) => {
        const { children } = props;
      
        const { ref, inView } = useInView({
            rootMargin: '-100px',
            triggerOnce: true,
        });
      
        return (
            <div className={`fade-element ${inView ? 'visible' : ''}`} ref={ref}>
                {children}
            </div>
        );
    };      

    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 2, delay: 1.5}}>
            <div className='nolMain'>
                <div>
                    <img className='mainImage' src={mainImg1} alt='nol-image' />
                </div>
                <div className='nolConcept'>
                    <div className='nolSubMenu'>
                        <h4>CONCEPT</h4> 
                    </div>
                </div>
                <div className='massage'>
                    <p>
                        nol（ノル）は美容師歴10年の店主が運営するカット専門店です。美容師歴10年で培ってきた自信のあるカット技術を1,500円というリーズナブルな価格で、幅広い年齢のお客様に素敵なヘアスタイルを提供します。                
                    </p>
                </div>
                <FadeAnimation>
                <div className='nolFirst'>
                    <div className='nolSubMenu'>
                        <h4>FIRST</h4>
                    </div>
                </div>
                <div className='massage'>
                    <p>
                        まず初めにカット専門店とは美容室のカット部分のサービスのみに絞ることにより、短時間かつ最安の価格でサービスを提供できるお店です。
                        <br /><br />※カットのみの提供となるため、シャンプー含む、カラーやパーマなどのメニューはございません。          
                    </p>
                </div>
                </FadeAnimation>
                <FadeAnimation>
                <div>
                    <div className='nolSubMenu'>
                        <h4>ABOUT</h4>
                    </div>
                    <div>
                        <img className='aboutImage' src={aboutImg} alt='nol-image' />
                    </div>
                    <div className='massage'>
                        <p>
                            nol（ノル）は予約不要で短時間かつリーズナブルな価格で高品質なヘアスタイルを提供します。忙しい方や定期的に通ってヘアスタイルをキープしたい方には特に気に入っていただけると思います。
                            <br />
                            <br />もちろん全体のヘアカットも素敵に致します。ぜひ一度ご来店ください。初めての方もカット専門店の良さを実感していただけると思います。          
                        </p>
                    </div>
                </div>
                </FadeAnimation>
                <FadeAnimation>
                    <div className='nolContact'>
                        <div className='nolSubMenu'>
                            <h4>CONTACT</h4>
                        </div>
                    </div>
                    <div className='nolMainTable'>
                        <table>
                            <tbody>
                                {TABLE_OBJECT.map((row) => (
                                    <tr key={row.menuName}>
                                        <td>{row.menuName}</td>
                                        <td>{row.value}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div style={{margin: '2rem'}}>
                        <div style={{marginBottom: '1rem'}}>お気軽にお問合せください</div>
                        <Button variant="outlined" color="inherit" href='tel:070-9057-5201'>電話をかける</Button>
                    </div>
                    <div className='map'>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3259.8765448698005!2d137.07620127632785!3d35.209543955739846!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60036621f27a4001%3A0x388e4c315ed6227d!2z44CSNDg5LTA4Nzgg5oSb55-l55yM54Cs5oi45biC6LWk6YeN55S677yR77yS77yW4oiS77yS!5e0!3m2!1sja!2sjp!4v1733140764462!5m2!1sja!2sjp" width="100%" height="300px"  loading="lazy"></iframe>
                    </div>
                    <footer className='nol-footer'>
                        copy right nol All Rights Reserved.
                    </footer>
                </FadeAnimation>                
            </div>
        </motion.div>
    );
}

export default NolMain;
