import React from 'react';
import './App.css';
import Button from '@mui/material/Button';
import NolHeader from './components/NolHeader';
import NolMain from './components/NolMain';
import sample from "./img/1.jpg"
import { motion } from 'framer-motion';

function App() {
  return (
    <div className="App">
        <NolHeader />
      <main className='nolMain'>
        <div>
            <NolMain />
        </div>
      </main>
    </div>
  );
}

export default App;
